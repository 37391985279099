import React, { Component } from "react";
import "../assets/css/tryon.css";
import "react-notifications/lib/notifications.css";

import html2canvas from "html2canvas";

import { FaceMesh } from "@mediapipe/face_mesh";

import { Camera } from "@mediapipe/camera_utils";

import {
  drawLip,
  drawBlush,
  drawFoundation,
  drawPowder,
  drawConcealer,
  drawContour,
  drawBrow,
  drawEyeShadow,
  drawEyeLiner,
  // drawMascara,
} from "@mediapipe/drawing_utils";


const faceMesh = new FaceMesh({
  locateFile: (file) => {
    return `https://zewellers-web-public-data.s3.ap-south-1.amazonaws.com/makeup/${file}`;
  },
});

const BLUSH_LEFT = [
  [447, 345],
  [345, 346],
  [346, 347],
  [347, 330],
  [330, 266],
  [266, 426],
  [426, 436],
  [436, 416],
  [416, 435],
  [435, 401],
  [401, 366],
  [366, 447],
];

const BLUSH_RIGHT = [
  [227, 137],
  [137, 177],
  [177, 215],
  [215, 192],
  [192, 216],
  [216, 206],
  [206, 36],
  [36, 101],
  [101, 118],
  [118, 117],
  [117, 116],
  [116, 227],
];

const CONCEALER_LEFT = [
  [465, 343],
  [343, 355],
  [355, 371],
  [371, 266],
  [266, 425],
  [425, 280],
  [280, 372],
  [372, 368],
  [368, 342],
  [342, 359],
  [359, 255],
  [255, 339],
  [339, 254],
  [254, 253],
  [253, 252],
  [252, 256],
  [256, 341],
  [341, 463],
  [463, 465],
];

const CONCEALER_RIGHT = [
  [139, 143],
  [143, 50],
  [50, 205],
  [205, 36],
  [36, 142],
  [142, 126],
  [126, 114],
  [114, 245],
  [245, 243],
  [243, 112],
  [112, 26],
  [26, 22],
  [22, 23],
  [23, 24],
  [24, 110],
  [110, 25],
  [25, 130],
  [130, 113],
  [113, 139],
];

const FACEMESH_LIPS = [
  [61, 185],
  [185, 40],
  [40, 39],
  [39, 37],
  [37, 0],
  [0, 267],
  [267, 269],
  [269, 270],
  [270, 409],
  [409, 291],

  [206, 292],
  [292, 308],
  [308, 415],
  [415, 310],
  [310, 311],
  [311, 312],
  [312, 13],
  [13, 82],
  [82, 81],
  [81, 80],
  [80, 191],
  [191, 78],
  [78, 62],
  [62, 76],
  [76, 61],

  [61, 76],
  [76, 62],
  [62, 78],
  [78, 95],
  [95, 88],
  [88, 178],
  [178, 87],
  [87, 14],
  [14, 317],
  [317, 402],
  [402, 318],
  [318, 324],
  [324, 308],
  [308, 292],
  [292, 291],

  [291, 375],
  [375, 321],
  [321, 405],
  [405, 314],
  [314, 17],
  [17, 84],
  [84, 91],
  [91, 146],
  [146, 61],
];

const FOUNDATION = [
  [10, 109],
  [109, 67],
  [67, 103],
  [103, 54],
  [54, 21],
  [21, 162],
  [162, 127],
  [127, 234],
  [234, 93],
  [93, 132],
  [132, 58],
  [58, 172],
  [172, 136],
  [136, 150],
  [150, 149],
  [149, 176],
  [176, 148],

  [61, 185],
  [185, 40],
  [40, 39],
  [39, 37],
  [37, 0],
  [0, 267],
  [267, 269],
  [269, 270],
  [270, 409],
  [409, 291],

  [291, 375],
  [375, 321],
  [321, 405],
  [405, 314],
  [314, 17],
  [17, 84],
  [84, 91],
  [91, 146],
  [146, 61],
  [61, 148],

  [148, 152],
  [152, 377],
  [377, 400],
  [400, 378],
  [378, 379],
  [379, 365],
  [365, 397],
  [397, 288],
  [288, 361],
  [361, 323],
  [323, 454],
  [454, 356],
  [356, 389],
  [389, 251],
  [251, 284],
  [284, 332],
  [332, 297],
  [297, 338],
  [338, 10],

  [133, 155],
  [155, 154],
  [154, 153],
  [153, 145],
  [145, 144],
  [144, 163],
  [163, 7],
  [7, 33],
  [33, 246],
  [246, 161],
  [161, 160],
  [160, 159],
  [159, 158],
  [158, 157],
  [157, 173],
  [173, 133],
  [133, 10],

  [263, 249],
  [249, 390],
  [390, 373],
  [373, 374],
  [374, 380],
  [380, 381],
  [381, 382],
  [382, 362],
  [362, 398],
  [398, 384],
  [384, 385],
  [385, 386],
  [386, 387],
  [387, 388],
  [388, 466],
  [466, 263],
  [263, 10],
  [10, 109],
  [109, 67],
  [67, 69],
  [69, 66],
  [66, 65],
  [65, 222],
  [222, 221],
  [221, 189],
  [189, 245],
  [245, 188],
  [188, 174],
  [174, 236],
  [236, 134],
  [134, 220],
  [220, 44],
  [44, 19],
];

const POWDER = [
  [10, 109],
  [109, 67],
  [67, 103],
  [103, 54],
  [54, 21],
  [21, 162],
  [162, 127],
  [127, 234],
  [234, 93],
  [93, 132],
  [132, 58],
  [58, 172],
  [172, 136],
  [136, 150],
  [150, 149],
  [149, 176],
  [176, 148],

  [61, 185],
  [185, 40],
  [40, 39],
  [39, 37],
  [37, 0],
  [0, 267],
  [267, 269],
  [269, 270],
  [270, 409],
  [409, 291],

  [291, 375],
  [375, 321],
  [321, 405],
  [405, 314],
  [314, 17],
  [17, 84],
  [84, 91],
  [91, 146],
  [146, 61],
  [61, 148],

  [148, 152],
  [152, 377],
  [377, 400],
  [400, 378],
  [378, 379],
  [379, 365],
  [365, 397],
  [397, 288],
  [288, 361],
  [361, 323],
  [323, 454],
  [454, 356],
  [356, 389],
  [389, 251],
  [251, 284],
  [284, 332],
  [332, 297],
  [297, 338],
  [338, 10],

  [133, 155],
  [155, 154],
  [154, 153],
  [153, 145],
  [145, 144],
  [144, 163],
  [163, 7],
  [7, 33],
  [33, 246],
  [246, 161],
  [161, 160],
  [160, 159],
  [159, 158],
  [158, 157],
  [157, 173],
  [173, 133],
  [133, 10],

  [263, 249],
  [249, 390],
  [390, 373],
  [373, 374],
  [374, 380],
  [380, 381],
  [381, 382],
  [382, 362],
  [362, 398],
  [398, 384],
  [384, 385],
  [385, 386],
  [386, 387],
  [387, 388],
  [388, 466],
  [466, 263],
  [263, 10],

  [10, 109],
  [109, 67],

  [67, 69],
  [69, 66],
  [66, 65],
  [65, 222],
  [222, 221],
  [221, 189],
  [189, 245],
  [245, 188],
  [188, 174],
  [174, 236],
  [236, 134],
  [134, 220],
  [220, 44],
  [44, 19],
];

const CONTOUR_RIGHT = [
  [234, 93],
  [93, 214],
  [214, 207],
  [207, 234],
];

const CONTOUR_LEFT = [
  [454, 436],
  [436, 432],
  [432, 323],
  [323, 454],
];

const BROW_LEFT = [
  [55, 53],
  [53, 46],
  [46, 124],
  [124, 156],
  [156, 70],
  [70, 63],
  [63, 107],
  [107, 55],
];

const BROW_RIGHT = [
  [276, 283],
  [283, 285],
  [285, 336],
  [336, 293],
  [293, 300],
  [300, 383],
  [383, 353],
  [353, 276],
];

const EYESHADOW_RIGHT = [
  [56, 28],
  [28, 27],
  [27, 29],
  [29, 30],
  [30, 247],
  [247, 130],
  [130, 226],
  [226, 113],
  [113, 225],
  [225, 224],
  [224, 223],
  [223, 222],
  [222, 221],
  [221, 56],
];

const EYESHADOW_LEFT = [
  [286, 258],
  [258, 257],
  [257, 259],
  [259, 260],
  [260, 467],
  [467, 342],
  [342, 445],
  [445, 444],
  [444, 443],
  [443, 442],
  [442, 441],
  [441, 286],
];
const EYELINER_RIGHT = [
  [243, 173],
  [173, 157],
  [157, 158],
  [158, 159],
  [159, 160],
  [160, 161],
  [161, 246],
  [246, 33],
  [33, 25],
  [25, 226],
  [226, 156],
  [156, 247],
  [247, 30],
  [20, 29],
  [29, 27],
  [27, 28],
  [28, 243],
];

const EYELINER_LEFT = [
  [463, 398],
  [398, 384],
  [384, 385],
  [385, 386],
  [386, 387],
  [387, 388],
  [388, 466],
  [466, 263],
  [263, 255],
  [255, 446],
  [446, 383],
  [383, 467],
  [467, 260],
  [260, 259],
  [259, 257],
  [257, 258],
  [258, 463]
];

// const EYEMASCARA_LEFT = [
//   [384, 385],
//   [385, 386],
//   [386, 387],
//   [387, 388],
//   [388, 466],
//   [466, 263],
//   [263, 255],
//   [255,383],
//   [383, 359],
//   [359, 467],
//   [467, 260],
//   [260, 259],
//   [259, 257],
//   [257, 384],
// ];

// const EYEMASCARA_RIGHT = [
//   [263, 467],
//   [467, 388],
//   [388, 259],
//   [259, 386],
//   [386, 258],
//   [258, 384],
//   [384, 414],
//   [414, 463],
//   // [398, 286],
//   // [286, 384],
//   // [384, 258],
//   // [258, 385],
//   // [385, 257],
//   // [257, 386],
//   // [386, 259],
//   // [259, 387],
//   // [387, 260],
//   // [260, 388],
//   // [388, 467],
//   // [467, 466],
//   // [466, 359],

//   // [359, 263],
//   // [263, 466],
//   // [466, 388],
//   // [388, 387],
//   // [387, 386],
//   // [386, 385],
//   // [385, 384],
//   // [384, 398],
//   // [398, 286],
//   // [286, 384],
//   // [384, 258],
//   // [258, 385],
//   // [385, 257],
//   // [257, 386],
//   // [386, 259],
//   // [259, 387],
//   // [387, 260],
//   // [260, 388],
//   // [388, 467],
//   // [467, 466],
//   // [466, 359],

//   // [263, 466],
//   // [466, 388],
//   // [388, 387],
//   // [387, 386],
//   // [386, 385],
//   // [385, 384],
//   // [384, 398],
// ];

// [359, 263],
//   [263, 466],
//   [466, 388],
//   [388, 387],
//   [387, 386],
//   [386, 385],
//   [385, 384],
//   [384, 398],
//   [398, 286],
//   [286, 384],
//   [384, 258],
//   [258, 385],
//   [385, 257],
//   [257, 386],
//   [386, 259],
//   [259, 387],
//   [387, 260],
//   [260, 388],
//   [388, 467],
//   [467, 466],
//   [466, 359],

let videoElement;
let canvasElement;
let canvasCtx;

class MakeupTryon extends Component {
  constructor() {
    super();
    this.state = {
      colorCode: "",
      cameraPermission: "true",
      varients: [],
      zoomValue: 0,
      loadingStatus: true,
      activeCategory: "",
      activeSubCategory: "",
      lipsActiveColor: "",
      blushActiveColor: "",
      concealerActiveColor: "",
      contourActiveColor: "",
      foundationActiveColor: "",
      powderActiveColor: "",
      browActiveColor: "",
      shadowActiveColor: "",
      linerActiveColor: "",
      lipsActive: false,
      blushActive: false,
      foundationActive: false,
      concealerActive: false,
      powderActive: false,
      contourActive: false,
      browActive: false,
      eyeShadowActive: false,
      eyeLinerActive: false,
      eyeMascaraActive: false,
      lipsColor: "",
      blushColor: "",
      foundationColor: "",
      concealerColor: "",
      powderColor: "",
      contourColor: "",
      browColor: "",
      eyeShadowColor: "",
      eyeLinerColor: "",
      eyeMascaraColor: "",

      capturedImage: "",

      notDetectd: 'false',
    };

    this.onResults = this.onResults.bind(this);
  }

  componentDidMount() {
    // this.tryonZewellersStart('#FF0000', 'blush&bronzer');
  }

  onResults(results) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    this.setState({ loadingStatus: false }, () => {
      this.setState({ loadingStatus: false });
    });
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    if (results.multiFaceLandmarks.length > 0) {
      for (const landmarks of results.multiFaceLandmarks) {
        if (this.state.blushActive === true) {
          drawBlush(canvasCtx, landmarks, BLUSH_LEFT, {
            color: this.state.blushColor,
            fillColor: this.state.blushColor,
          });
          drawBlush(canvasCtx, landmarks, BLUSH_RIGHT, {
            color: this.state.blushColor,
            fillColor: this.state.blushColor,
          });
        }
        if (this.state.lipsActive === true) {
          drawLip(canvasCtx, landmarks, FACEMESH_LIPS, {
            color: this.state.lipsColor,
            fillColor: this.state.lipsColor,
          });
        }
        if (this.state.foundationActive === true) {
          drawFoundation(canvasCtx, landmarks, FOUNDATION, {
            color: this.state.foundationColor,
            fillColor: this.state.foundationColor,
          });
        }
        if (this.state.concealerActive === true) {
          drawConcealer(canvasCtx, landmarks, CONCEALER_RIGHT, {
            color: this.state.concealerColor,
            fillColor: this.state.concealerColor,
            lineWidth: 10,
          });
          drawConcealer(canvasCtx, landmarks, CONCEALER_LEFT, {
            color: this.state.concealerColor,
            fillColor: this.state.concealerColor,
            lineWidth: 10,
          });
        }
        if (this.state.powderActive === true) {
          drawPowder(canvasCtx, landmarks, POWDER, {
            color: this.state.powderColor,
            fillColor: this.state.powderColor,
          });
        }
        if (this.state.contourActive === true) {
          drawContour(canvasCtx, landmarks, CONTOUR_RIGHT, {
            color: this.state.contourColor,
            fillColor: this.state.contourColor,
          });
          drawContour(canvasCtx, landmarks, CONTOUR_LEFT, {
            color: this.state.contourColor,
            fillColor: this.state.contourColor,
          });
        }
        if (this.state.browActive === true) {
          drawBrow(canvasCtx, landmarks, BROW_RIGHT, {
            color: this.state.browColor,
            fillColor: this.state.browColor,
          });

          drawBrow(canvasCtx, landmarks, BROW_LEFT, {
            color: this.state.browColor,
            fillColor: this.state.browColor,
          });
        }

        if (this.state.eyeShadowActive === true) {
          drawEyeShadow(canvasCtx, landmarks, EYESHADOW_RIGHT, {
            color: this.state.eyeShadowColor,
            fillColor: this.state.eyeShadowColor,
          });

          drawEyeShadow(canvasCtx, landmarks, EYESHADOW_LEFT, {
            color: this.state.eyeShadowColor,
            fillColor: this.state.eyeShadowColor,
          });
        }

        if (this.state.eyeLinerActive === true) {
          drawEyeLiner(canvasCtx, landmarks, EYELINER_RIGHT, {
            color: this.state.eyeLinerColor,
            fillColor: this.state.eyeLinerColor,
          });

          drawEyeLiner(canvasCtx, landmarks, EYELINER_LEFT, {
            color: this.state.eyeLinerColor,
            fillColor: this.state.eyeLinerColor,
          });
        }

        if (this.state.eyeMascaraActive === true) {
          // drawConnectors(canvasCtx, landmarks, EYEMASCARA_RIGHT, {
          //   color: this.state.eyeMascaraColor,
          //   fillColor: this.state.eyeMascaraColor,
          // });

          // drawMascara(canvasCtx, landmarks, EYEMASCARA_LEFT, {
          //   color: this.state.eyeMascaraColor,
          //   fillColor: this.state.eyeMascaraColor,
          // });
        }
      }
      this.setState({ notDetectd: false }, () => {
        this.setState({ notDetectd: false })
      })
    }
    else {
      this.setState({ notDetectd: true }, () => {
        this.setState({ notDetectd: true })
      })
      // alert("Your face is not detected ! Please align your face with camera to experience Try On.")
    }
    canvasCtx.restore();
  }

  APIcall = (color, category) => {
    faceMesh.onResults(this.onResults);
    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await faceMesh.send({ image: videoElement });
      },
      width: 1280,
      height: 720,
    });
    camera.start();
    var hex = color;
    var red = parseInt(hex[1] + hex[2], 16);
    var green = parseInt(hex[3] + hex[4], 16);
    var blue = parseInt(hex[5] + hex[6], 16);
    this.setState({ colorCode: [red, green, blue] }, () => {
      this.setState({ colorCode: [red, green, blue] });
    });
    if (category === "lipstick") {
      this.setState({ lipsActiveColor: color }, () => {
        this.setState({ lipsActiveColor: color });
      });
      this.setState({ lipsActive: true });
      this.setState({ lipsColor: [red, green, blue] });
    }
    if (category === "lipbam") {
      this.setState({ lipsActiveColor: color }, () => {
        this.setState({ lipsActiveColor: color });
      });
      this.setState({ lipsActive: true });
      this.setState({ lipsColor: [red, green, blue] });
    }
    if (category === "blush&bronzer") {
      this.setState({ blushActiveColor: color }, () => {
        this.setState({ blushActiveColor: color });
      });
      this.setState({ blushActive: true });
      this.setState({ blushColor: [red, green, blue] });
    }
    if (category === "foundation") {
      this.setState({ foundationActiveColor: color }, () => {
        this.setState({ foundationActiveColor: color });
      });
      this.setState({ foundationActive: true });
      this.setState({ foundationColor: [red, green, blue] });
    }
    if (category === "concealer") {
      this.setState({ concealerColor: color }, () => {
        this.setState({ concealerActiveColor: color });
      });
      this.setState({ concealerActive: true });
      this.setState({ concealerColor: [red, green, blue] });
    }
    if (category === "powder") {
      this.setState({ powderActiveColor: color }, () => {
        this.setState({ powderActiveColor: color });
      });
      this.setState({ powderActive: true });
      this.setState({ powderColor: [red, green, blue] });
    }
    if (category === "contour") {
      this.setState({ contourActiveColor: color }, () => {
        this.setState({ contourblushActiveColor: color });
      });
      this.setState({ contourActive: true });
      this.setState({ contourColor: [red, green, blue] });
    }
    if (category === "brow") {
      this.setState({ browActiveColor: color }, () => {
        this.setState({ browActiveColor: color });
      });
      this.setState({ browActive: true });
      this.setState({ browColor: [red, green, blue] });
    }
    if (category === "shadow") {
      this.setState({ shadowActiveColor: color }, () => {
        this.setState({ shadowActiveColor: color });
      });
      this.setState({ eyeShadowActive: true });
      this.setState({ eyeShadowColor: [red, green, blue] });
    }
    if (category === "liner") {
      this.setState({ linerActiveColor: color }, () => {
        this.setState({ linerActiveColor: color });
      });
      this.setState({ eyeLinerActive: true });
      this.setState({ eyeLinerColor: [red, green, blue] });
    }
  }

  captureCamera = () => {
    return this.state.capturedImage
  }

  captureImage = () => {
    html2canvas(document.querySelector("#tryonParent")).then(canvas => {
      this.setState({ capturedImage: canvas.toDataURL() }, () => {
        this.setState({ capturedImage: canvas.toDataURL() })
      });
    });
  };


  increment = () => {
    if (this.state.zoomValue >= 0 && this.state.zoomValue <= 4) {
      this.setState({ zoomValue: this.state.zoomValue + 2 }, () => {
        var tryonParent = document.getElementById("canvas_id");
        if (tryonParent) {
          tryonParent.style.transform =
            "translate(0, 0)scaleX(-1) scale(1." + this.state.zoomValue + ")";
          tryonParent.style.transition = "transform .6s";
        }
      });
    }
  };

  decrement = () => {
    if (this.state.zoomValue >= 2 && this.state.zoomValue <= 6) {
      this.setState({ zoomValue: this.state.zoomValue - 2 }, () => {
        var tryonParent = document.getElementById("canvas_id");
        if (tryonParent) {
          tryonParent.style.transition = "transform .6s";
          tryonParent.style.transform =
            "scaleX(-1) scale(1." + this.state.zoomValue + ")";
        }
      });
    }
  };

  tryonZewellersStart = (color, category) => {
    console.log("...loading...");
    videoElement = document.getElementsByClassName("input_video")[0];
    canvasElement = document.getElementsByClassName("output_canvas")[0];
    canvasCtx = canvasElement.getContext("2d");

    this.APIcall(color, category);
  };

  clearCanvasHome = () => {
    const video = document.querySelector("video");
    const mediaStream = video.srcObject;
    const tracks = mediaStream.getTracks();
    tracks[0].stop();
    tracks.forEach((track) => track.stop());
    console.log("Cleared ...");
    window.stop();
  };


  clearAllFilter = () => {
    this.clearCanvasHome()
    this.setState({ lipsActive: false });
    this.setState({ foundationActive: false });
    this.setState({ blushActive: false });
    this.setState({ concealerActive: false });
    this.setState({ powderActive: false });
    this.setState({ contourActive: false });
    this.setState({ browActive: false });
    this.setState({ eyeShadowActive: false });
    this.setState({ eyeLinerActive: false });
    this.setState({ eyeMascaraActive: false });

    this.setState({ lipsActiveColor: "" });
    this.setState({ blushActiveColor: "" });
    this.setState({ concealerActiveColor: "" });
    this.setState({ contourActiveColor: "" });
    this.setState({ foundationActiveColor: "" });
    this.setState({ powderActiveColor: "" });
    this.setState({ browActiveColor: "" });
    this.setState({ shadowActiveColor: "" });
    this.setState({ linerActiveColor: "" });
  }

  tryonClick = (color, category) => {
    var hex = color;
    var red = parseInt(hex[1] + hex[2], 16);
    var green = parseInt(hex[3] + hex[4], 16);
    var blue = parseInt(hex[5] + hex[6], 16);
    this.setState({ colorCode: [red, green, blue] }, () => {
      this.setState({ colorCode: [red, green, blue] });
    });
    if (category === "lipstick") {
      console.log("inn")
      this.setState({ lipsActiveColor: color }, () => {
        this.setState({ lipsActiveColor: color });
      });
      this.setState({ lipsActive: true });
      this.setState({ lipsColor: [red, green, blue] });
    }
    if (category === "lipbam") {
      this.setState({ lipsActiveColor: color }, () => {
        this.setState({ lipsActiveColor: color });
      });
      this.setState({ lipsActive: true });
      this.setState({ lipsColor: [red, green, blue] });
    }
    if (category === "blush&bronzer") {
      this.setState({ blushActiveColor: color }, () => {
        this.setState({ blushActiveColor: color });
      });
      this.setState({ blushActive: true });
      this.setState({ blushColor: [red, green, blue] });
    }
    if (category === "foundation") {
      this.setState({ foundationActiveColor: color }, () => {
        this.setState({ foundationActiveColor: color });
      });
      this.setState({ foundationActive: true });
      this.setState({ foundationColor: [red, green, blue] });
    }
    if (category === "concealer") {
      this.setState({ concealerColor: color }, () => {
        this.setState({ concealerActiveColor: color });
      });
      this.setState({ concealerActive: true });
      this.setState({ concealerColor: [red, green, blue] });
    }
    if (category === "powder") {
      this.setState({ powderActiveColor: color }, () => {
        this.setState({ powderActiveColor: color });
      });
      this.setState({ powderActive: true });
      this.setState({ powderColor: [red, green, blue] });
    }
    if (category === "contour") {
      this.setState({ contourActiveColor: color }, () => {
        this.setState({ contourblushActiveColor: color });
      });
      this.setState({ contourActive: true });
      this.setState({ contourColor: [red, green, blue] });
    }
    if (category === "brow") {
      this.setState({ browActiveColor: color }, () => {
        this.setState({ browActiveColor: color });
      });
      this.setState({ browActive: true });
      this.setState({ browColor: [red, green, blue] });
    }
    if (category === "shadow") {
      this.setState({ shadowActiveColor: color }, () => {
        this.setState({ shadowActiveColor: color });
      });
      this.setState({ eyeShadowActive: true });
      this.setState({ eyeShadowColor: [red, green, blue] });
    }
    if (category === "liner") {
      this.setState({ linerActiveColor: color }, () => {
        this.setState({ linerActiveColor: color });
      });
      this.setState({ eyeLinerActive: true });
      this.setState({ eyeLinerColor: [red, green, blue] });
    }
  }

  render() {
    return (
      <>

        <div className="row">
          <div
            className="col-10"
            id="tryonParent"
            style={{
              background: "white",
              height: "65vh",
              textAlign: "center",
            }}
          >
            {this.state.cameraPermission === "true" ? (
              <>
                {this.state.loadingStatus === true ? (
                  <>


                    {/* Loader Section */}
                    <div
                      id="loader-screen"
                      style={{
                        width: "inherit",
                        height: "inherit",
                        backgroundColor: "white",
                        position: "absolute",
                      }}
                    >
                      <div
                        className="loading-model"
                        style={{
                          height: "15%",
                          width: "80px",
                          backgroundImage:
                            "url(https://zewellers-web-public-data.s3.ap-south-1.amazonaws.com/makeup/img/makeup-tryon-loader.gif)",
                          backgroundSize: "cover",
                          backgroundPosition: "center 0",
                          backgroundRepeat: "no-repeat",
                          display: "block",
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          margin: "auto",
                        }}
                      >
                        <center>
                          <div className="messageAnimation"></div>
                        </center>
                      </div>
                    </div>
                    {/* Loader Section */}
                  </>
                ) : (
                  <></>
                )}
                {this.state.notDetectd === true ?
                  <div style={{
                    zIndex: '1', position: 'absolute', width: 'inherit', height: 'inherit',
                    backgroundColor: 'black', opacity: '0.5'
                  }}>
                    <div style={{
                      height: "15%",
                      width: "70%",
                      backgroundSize: "cover",
                      backgroundPosition: "center 0",
                      backgroundRepeat: "no-repeat",
                      display: "block",
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      color: 'white',
                      margin: "auto",
                    }}>
                      <img alt={'detection-icon'} src="https://zewellers-web-public-data.s3.ap-south-1.amazonaws.com/detection-icon.png" width={75} height={75} />
                      <p style={{ fontSize: '20px' }}>Your face is not detected!
                        <br />Please align your face with<br /> camera to experience Try On.</p>
                    </div>
                  </div>
                  : ''}
                <video
                  className="input_video"
                  style={{ display: "none" }}
                ></video>
                <canvas
                  id="canvas_id"
                  className="output_canvas"
                  style={{ transform: "scalex(-1)" }}
                  width="1280px"
                  height="720px"
                ></canvas>
              </>
            ) : (
              <>
                ''
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MakeupTryon;
